<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Count number of days from a date to today</p>
      <v-spacer></v-spacer>
      <dev-menu></dev-menu>
    </div>

    <v-card class="container">
      <v-row justify="center" align="center">
        <v-col cols="3" justify="center"
          ><v-date-picker v-model="day" @input="calculateNDays"></v-date-picker
        ></v-col>
        <v-col cols="9" valign="center" justify="center">
          <v-card class="pa-2" tile>
            <div v-if="nDays">
              <v-text-field
                v-model="command"
                label="Command line"
              ></v-text-field>
              <br />
              The {{ day }} is <b>{{ nDays }}</b> days from today <br /><br />
              <pre>
    --n-days={{ nDays }}
                </pre
              >
              <div v-if="command">
                <pre>
    /bin/bash scripts/crawl_from_n_days.sh "{{ command }}" {{ nDays }} 2
    # or
    /bin/bash scripts/crawl_from_n_days.sh "{{ command }}" {{ nDays }} 2 EXEC
  
    # help: 
    /bin/bash scripts/crawl_from_n_days.sh
                  </pre
                >
              </div>
            </div>

            <div v-else>First pick a date on the calendar</div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import DevMenu from "@/views/menus/DevMenu.vue";
export default {
  name: "CopyPaste",
  components: { DevMenu },
  data() {
    return {
      nDays: null,
      day: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      command: "",
    };
  },
  methods: {
    calculateNDays(day) {
      // console.log("day", day);
      // console.log("this.day", this.day);
      let date_1 = new Date(day); // new Date('10/25/2021');
      let date_2 = new Date();
      let difference = date_1.getTime() - date_2.getTime();
      this.nDays = Math.abs(Math.ceil(difference / (1000 * 3600 * 24)));
    },
  },
  mounted() {},
  computed: {
    // myComputeFunction() {},
  },
  watch: {
    // isOpenCreateCronDialog(visible) {
    //   if (visible) {
    //     this.identifier = "";
    //     this.$refs.FormCron.resetValidation();
    //   }
    // },
  },
};
</script>
